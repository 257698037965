import { paths } from 'src/routes/paths';

// API
// ----------------------------------------------------------------------

// export const HOST_API = 'http://localhost:3001/local';
export const HOST_API = import.meta.env.VITE_API_BASE_URL;
export const ASSETS_API = import.meta.env.VITE_ASSETS_CDN_BASE_URL;

export const ENVIRONMENT = import.meta.env.VITE_ENVIRONMENT;

export const SITE_URL = import.meta.env.VITE_SITE_URL;
export const GTM_ID = import.meta.env.VITE_GTM_TAG;

export const FIREBASE_API = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APPID,
  measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
};

export const AMPLIFY_API = {
  userPoolId: import.meta.env.VITE_AWS_AMPLIFY_USER_POOL_ID,
  userPoolWebClientId: import.meta.env.VITE_AWS_AMPLIFY_USER_POOL_WEB_CLIENT_ID,
  region: import.meta.env.VITE_AWS_AMPLIFY_REGION,
};

export const AUTH0_API = {
  clientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
  domain: import.meta.env.VITE_AUTH0_DOMAIN,
  callbackUrl: import.meta.env.VITE_AUTH0_CALLBACK_URL,
};

export const SUPABASE_API = {
  url: import.meta.env.VITE_SUPABASE_URL,
  key: import.meta.env.VITE_SUPABASE_ANON_KEY,
};

export const MAPBOX_API = import.meta.env.VITE_MAPBOX_API;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = paths.root; // as '/dashboard'
export const PATH_AFTER_LOGIN_FOR_COLLAB = paths.staff.results;

export const SENTRY_CONFIG = {
  dsn: import.meta.env.VITE_SENTRY_DSN,
  env: import.meta.env.VITE_SENTRY_ENV,
};

export const TYPEFORM = {
  formId: 'cXqFZwed',
};

export const DATOCMS = {
  endpoint: 'https://graphql.datocms.com/',
  apiToken: import.meta.env.VITE_DATOCMS_API_TOKEN,
  env: import.meta.env.VITE_DATO_ENV,
};

export const OTHER_NET_SITE_URL = 'https://new-english-teaching.it';
